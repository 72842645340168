@import "../../style/widthPicker.scss";
.gallery {
  padding-top: 10px;
}
.list {
  grid-template-columns: repeat(3, 1fr) !important;
  @include screen("medium") {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
.item {
  cursor: pointer;
}
.skeleton {
  width: 32vw;
  height: 40vh !important;
  @include screen("medium") {
    width: 49vw;
    height: 30vh !important;
  }
}
