@import "../../../style/variables";
@import "../../../style/widthPicker.scss";
.arrow {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  left: 50%;
  transform: translateX(-50%);
  animation: arrowMove 1s infinite alternate;
  cursor: pointer;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.coverImage {
  width: 100%;
  height: calc(100vh - $headerHeight);
  object-fit: cover;
}
.skeleton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  background-color: lightgray !important;
  &_title {
    width: 400px;
    height: 40px !important;
    @include screen("medium") {
      width: 200px;
    }
  }
  &_location {
    width: 300px;
    height: 20px !important;
    @include screen("medium") {
      width: 150px;
    }
  }
  &_text {
    width: 600px;
    height: 400px !important;
    @include screen("medium") {
      width: 300px;
      height: 200px !important;
    }
  }
}

@keyframes arrowMove {
  0% {
    top: 78%;
  }
  100% {
    top: 80%;
  }
}
