@import "../../../style/variables";
.submenu {
  width: 600px;
  padding: 25px 0 20px 0;
  &_item {
    line-height: 4;
    text-align: center;
    font-size: 20px;
    transition: all 0.2s;
  }
  &_item:hover {
    background: rgba(220, 220, 220);
  }
  &_link {
    display: block;
    color: $primary-color-text;
    letter-spacing: 1px;
  }
  &_title {
    text-align: center;
    margin-bottom: 15px;
    color: grey;
  }
}
@media (max-width: 592px) {
  .submenu {
    padding: 15px 0 30px 0;
    width: 100%;
    &_item {
      line-height: 3;
      text-align: center;
      font-size: 16px;
    }
  }
}
