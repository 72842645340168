$small: 592px;
$medium: 768px;
$large: 992px;
$extraLarge: 1200px;

@mixin screen($size) {
  @if $size == "small" {
    @media (max-width: $small) {
      @content;
    }
  } @else if $size == "medium" {
    @media (max-width: $medium) {
      @content;
    }
  } @else if $size == "large" {
    @media (max-width: $large) {
      @content;
    }
  } @else if $size == "extraLarge" {
    @media (max-width: $extraLarge) {
      @content;
    }
  } @else if $size == "greaterThanMedium" {
    @media (min-width: $medium) {
      @content;
    }
  } @else if $size == "greaterThanLarge" {
    @media (min-width: $large) {
      @content;
    }
  } @else if $size == "greaterThanExtraLarge" {
    @media (min-width: $extraLarge) {
      @content;
    }
  } @else {
    @content;
  }
}
