.swiper {
  &__wrapper {
    width: 85vw;
    margin: 0 auto;
  }
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.box {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media (max-width: 992px) {
  .box {
    height: 40vh;
  }
}
@media (max-width: 592px) {
  .swiper {
    &__wrapper {
      width: 95vw;
    }
  }
  .box {
    height: 25vh;
  }
}
