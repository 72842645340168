@import "../../style/variables";

.header {
  position: sticky;
  z-index: 1000;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
}

.nav {
  height: $headerHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 50px 0 90px;
}
.logo h1 {
  font-weight: 300;
  text-transform: uppercase;
  color: $primary-color-text;
}
.menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  justify-content: end;
  margin-right: 2rem;
  font-weight: 400;
  height: 100%;

  &_active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  &_item {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 20px;
    padding: 0 10px;
    cursor: pointer;
    color: $primary-color-text;
    a {
      color: $primary-color-text;
    }
  }
  &_item:hover {
    background: rgba(240, 240, 240);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@media (max-width: 992px) {
  .header {
    padding: 0 35px;
  }
  .nav {
    padding: 12px 0 12px;
  }
  .menu {
    display: none;
    &_item {
      font-size: 20px;
      margin-left: 50px;
    }
  }
  .logo {
    display: block;
    margin: 0 auto;
  }
}
@media (max-width: 800px) {
  .menu {
    &_item {
      margin-left: 10px;
      font-size: 20px;
    }
    &_btn {
      height: 20px;
    }

    & > li {
      margin-bottom: 40px;
      margin-left: 0px;
    }
    a {
      color: white;
    }
  }
}
@media (max-width: 592px) {
  .header {
    padding: 0 10px;
  }
  .nav {
    padding-right: 0;
  }
}
