@import "./widthPicker.scss";

.container {
  max-width: 1140px;
  padding: 0 5px;
  margin-right: auto;
  margin-left: auto;
  @include screen("extraLarge") {
    max-width: 992px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 768px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 560px;
  }
}

@media (max-width: 500px) {
  .container {
    max-width: 400px;
  }
}

@media (max-width: 400px) {
  .container {
    max-width: 100%;
  }
}
