@import "../../style//widthPicker.scss";
.footer {
  padding: 20px 50px 20px 90px;
  background-color: #f7f7f7;
  margin-top: 30px;
  padding-bottom: 50px;
  @include screen(small) {
    padding: 20px 20px 80px 20px;
  }
}
.links {
  display: flex;
  justify-content: space-between;
}
.logo {
  display: block;
  margin-bottom: 10px;
  text-align: center;
}
.copyright {
  text-align: center;
  margin-top: 20px;
  @include screen(small) {
    font-size: 10px;
    margin-top: 10px;
  }
}
.social {
  display: flex;
  &_item {
    font-size: 20px;
    margin-right: 15px;
    cursor: pointer;
  }
  &_text {
    text-align: center;
    margin-top: 10px;
  }
}
