@import "../../style/widthPicker.scss";
.about {
  padding-top: 150px;
  padding-bottom: 100px;
  @include screen("large") {
    padding-top: 80px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &_inner {
    display: flex;
    justify-content: space-between;
    @include screen("large") {
      flex-direction: column;
    }
    @include screen("small") {
      padding: 0 30px;
    }
  }
  &_description {
    max-width: 660px;
    @include screen("extraLarge") {
      max-width: 500px;
    }
    @include screen("large") {
      max-width: 100%;
    }
  }
  p {
    text-align: left;
    font-size: 18px;
    color: #183c4d;
    margin-bottom: 27px;
    line-height: 25px;
    @include screen("small") {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  &_bold {
    font-weight: 600;
    font-size: 30px;
    line-height: 25px;
  }
  h4 {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 25px;
    color: #183c4d;
    margin-bottom: 35px;
    text-align: left;
  }
}
.insta {
  text-decoration: underline;
}
.photo {
  width: 420px;
  aspect-ratio: 2/3;
  @include screen("large") {
    width: 300px;
    height: 300px;
    margin-bottom: 20px;
  }
  @include screen("small") {
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include screen("large") {
      border-radius: 50%;
    }
  }
}
