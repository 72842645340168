.go_top_button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}
.active {
  opacity: 1;
  pointer-events: auto;
}
.arrow {
  font-size: 23px;
  transform: rotate(180deg);
}
@media (max-width: 992px) {
  .go_top_button {
    bottom: 65px;
    right: 20px;
    width: 40px;
    height: 40px;
  }
  .arrow {
    font-size: 18px;
  }
}
