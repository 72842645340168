.dropdown-menu {
  position: absolute;
  top: 70px;
  left: 0;
  width: 200px;
  list-style: none;
  text-align: start;
  z-index: 1;
  background: rgba(240, 240, 240);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 0;
}

.dropdown-menu li {
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.2s;
}

.dropdown-menu li:hover {
  background: rgba(220, 220, 220);
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
