.gallery {
  &__inner {
    padding-top: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    grid-auto-rows: 300px;
    grid-gap: 10px;
    grid-auto-flow: dense;
  }
  &__skeleton {
    background-color: #928e8e;
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__item {
    cursor: pointer;
  }

  &__item:nth-child(1n) {
    grid-row: span 2;
    grid-column: span 2;
  }
  &__item:nth-child(2n) {
    grid-row: span 2;
    grid-column: span 1;
  }
  &__item:nth-child(3n) {
    grid-row: span 2;
    grid-column: span 1;
  }

  &__item:nth-child(4n) {
    grid-row: span 1;
    grid-column: span 1;
  }

  &__item:nth-child(5n) {
    grid-row: span 2;
    grid-column: span 1;
  }

  &__item:nth-child(6n) {
    grid-row: span 2;
    grid-column: span 1;
  }

  &__item:nth-child(7n) {
    grid-row: span 2;
    grid-column: span 1;
  }

  &__item:nth-child(8n) {
    grid-row: span 2;
    grid-column: span 1;
  }

  &__item:nth-child(9n) {
    grid-row: span 2;
    grid-column: span 1;
  }

  &__item:nth-child(10n) {
    grid-row: span 1;
    grid-column: span 1;
  }

  &__item:nth-child(11n) {
    grid-row: span 2;
    grid-column: span 1;
  }

  &__item:nth-child(12n) {
    grid-row: span 2;
    grid-column: span 1;
  }

  &__item:nth-child(13n) {
    grid-row: span 2;
    grid-column: span 1;
  }

  &__item:nth-child(14n) {
    grid-row: span 1;
    grid-column: span 1;
  }
}

@media (max-width: 768px) {
  .gallery {
    &__inner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 230px;
      grid-gap: 8px;
    }

    &__item:nth-child(1) {
      grid-row: span 1;
      grid-column: span 1;
    }
  }
}
@media (max-width: 650px) {
  .gallery__inner {
    grid-auto-rows: 150px;
  }
}
@media (max-width: 500px) {
  .gallery__inner {
    grid-auto-rows: 100px;
  }
}
