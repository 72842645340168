@import "../../style//widthPicker.scss";
.story__card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton {
  width: 100%;
  background-color: lightgray !important;
  padding: 20px 0;
  margin: 30px 0;

  &_title {
    width: 300px;
    height: 50px !important;
    margin: 0 auto;
  }
  &_location {
    width: 200px;
    height: 30px !important;
    margin: 0 auto 20px auto !important;
  }
  &_swiper {
    width: 80%;
    height: 60vh !important;
    margin: 0 auto;
    @include screen("large") {
      height: 35vh !important;
    }
    @include screen("small") {
      height: 25vh !important;
    }
  }
}
