.btMenu {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4px);
  z-index: 8888; // it should be less than 9999 coz lightbox has the highest z-index
  transition: all 0.4s;
  bottom: -60px;
  height: 60px;

  &_item {
    font-size: 28px;
  }
  &_show {
    bottom: 0;
  }
}
@media (max-width: 992px) {
  .btMenu {
    display: block;
  }
}
